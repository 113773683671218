import _ from 'lodash'
import * as constants from './constants'
import * as selectors from './selectors'
import sdk from 'services/vigilanceHubService'

class NotificationsActions {
  fetchNotifications = ({ notificationsType = 'notifications', size = 10, isNotViewed }) => ({
    type: notificationsType === "notifications"? constants.FETCH_NOTIFICATIONS: constants.FETCH_LATEST_NOTIFICATIONS,
    promise: (dispatch, getState) => {
      let params = {
        page: 1,
        size,
        isNotViewed
      }

      return sdk.users.fetchPersonalNotifications(params)
    },
  })

  fetchNotificationsNext = ({ notificationsType = 'notifications', size = 10, isNotViewed}) => ({
    type: notificationsType === "notifications"? constants.FETCH_NOTIFICATIONS_NEXT: constants.FETCH_LATEST_NOTIFICATIONS_NEXT,
    promise: (dispatch, getState) => {
      const page = notificationsType === "notifications"? selectors.getNotificationsPage(getState()): selectors.getLatestNotificationsPage(getState())

      let params = {
        page: page + 1,
        size,
        isNotViewed
      }

      return sdk.users.fetchPersonalNotifications(params)
    },
  })

  fetchLatestNotifications = ({}) => ({
    type: constants.FETCH_LATEST_NOTIFICATIONS,
    promise: (dispatch, getState) => {
      let params = {
        page: 1,
        size: 2,
        order: [['isRead', 'ASC'],['createdAt', 'DESC']]
      }

      return sdk.users.fetchPersonalNotifications(params)
    },
  })

  fetchReportNotifications = ({reportId}) => ({
    type: constants.FETCH_REPORT_NOTIFICATIONS,
    promise: (dispatch, getState) => {
      let params = {
        reportId
      }

      return sdk.notifications.getNotificationsWithReportId(params)
    },
  })

  setNotificationUserStatus = ({ id, status }) => ({
    type: constants.SET_NOTIFICATIONS_USER_STATUS,
    promise: (dispatch, getState) => {
      return sdk.users.setNotificationUserStatus({ id, status})     
    }
  })

  changeActionedStatus = ({ id, isActioned }) => ({
    type: constants.CHANGE_ACTIONED_STATUS,
    promise: (dispatch, getState) => {
      return sdk.notifications.changeActionedStatus({ id, isActioned })
    }
  })

  getTotalNotificationsNotViewed = () => ({
    type: constants.GET_TOTAL_NOTIFICATION_NOT_VIEWED,
    promise: (dispatch, getState) => {
      return sdk.users.getTotalNotificationsNotViewed()
    }
  })

  resetStore = (notificationsType = "notifications") => ({
    type: notificationsType === "notifications"? constants.RESET_NOTIFICATIONS_STORE: constants.RESET_LATEST_NOTIFICATIONS_STORE,
  })
}

export default new NotificationsActions()
