import _ from 'lodash'
import { PENDING, SUCCESS, FAIL } from '../../reduxPromise'
import moment from 'moment'

class NotificationsReducers {
  fetchNotifications (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          notifications: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS,
            lastFetchedAt: moment().format("YYYY-MM-DD hh:mm:ss")
          },
        }
      case FAIL:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchNotificationsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const { results } = state.notifications
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          notifications: {
            ...state.notifications,
            total,
            page: state.notifications.page + 1,
            language,
            results: [ ...results, ...newResults ],
            status: SUCCESS,
          }
        }
      case FAIL:
        return {
          ...state,
          notifications: {
            ...state.notifications,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestNotifications (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          latestNotifications: {
            ...state.latestNotifications,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          latestNotifications: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS,
            lastFetchedAt: moment().format("YYYY-MM-DD hh:mm:ss")
          }
        }
      case FAIL:
        return {
          ...state,
          latestNotifications: {
            ...state.latestNotifications,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchReportNotifications (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          reportNotifications: {
            ...state.reportNotifications,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const results = _.get(action, 'result.results')
        const total = _.get(action, 'result.total')
        const language = _.get(action, 'result.language')
        return {
          ...state,
          reportNotifications: {
            total,
            page: 1,
            language,
            results,
            status: SUCCESS,
          }
        }
      case FAIL:
        return {
          ...state,
          reportNotifications: {
            ...state.reportNotifications,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  fetchLatestNotificationsNext (state, action) {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          latestNotifications: {
            ...state.latestNotifications,
            status: PENDING,
            action: action.type
          }
        }
      case SUCCESS:
        const { results } = state.latestNotifications
        const newResults = _.get(action, 'result.results')
        const total = _.get(action, 'result.total') || 0
        const language = _.get(action, 'result.language')
        return {
          ...state,
          latestNotifications: {
            ...state.latestNotifications,
            total,
            page: state.latestNotifications.page + 1,
            language,
            results: [ ...results, ...newResults ],
            status: SUCCESS
          }
        }
      case FAIL:
        return {
          ...state,
          latestNotifications: {
            ...state.latestNotifications,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  setNotificationUserStatus = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: PENDING,
            action: action.type,
          },
          latestNotifications: {
            ...state.latestNotifications,
            status: PENDING,
            action: action.type,
          },
        }
      case SUCCESS:
        return {
          ...state,
          notification: {
            ...state.notification,
            lastFetchedAt: undefined
          },
          latestNotifications: {
            ...state.latestNotifications,
            lastFetchedAt: undefined 
          },
          totalNotificationsNotViewed: {
            total: 0,
            lastFetchedAt: undefined
          }
        }
      case FAIL:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: FAIL,
            error: action.error
          },
          latestNotification: {
            ...state.latestNotification,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  changeActionedStatus = (state, action) => {
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: PENDING,
            action: action.type,
          },
          latestNotifications: {
            ...state.latestNotifications,
            status: PENDING,
            action: action.type,
          },
        }
      case SUCCESS:
        return {
          ...state,
          notification: {
            ...state.notification,
            lastFetchedAt: undefined
          },
          latestNotifications: {
            ...state.latestNotifications,
            lastFetchedAt: undefined 
          },
          totalNotificationsNotViewed: {
            total: 0,
            lastFetchedAt: undefined
          }
        }
      case FAIL:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: FAIL,
            error: action.error
          },
          latestNotification: {
            ...state.latestNotification,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

  getTotalNotificationsNotViewed = (state, action) => {
    const total = _.get(action, 'result.total')
    switch (action.status) {
      case PENDING:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: PENDING,
            action: action.type
          },
          latestNotifications: {
            ...state.latestNotifications,
            status: PENDING,
            action: action.type       
          }
        }
      case SUCCESS:
        return {
          ...state,
          totalNotificationsNotViewed: {
            total: total,
            lastFetchedAt: moment().format("YYYY-MM-DD hh:mm:ss")
          }
        }
      case FAIL:
        return {
          ...state,
          notification: {
            ...state.notification,
            status: FAIL,
            error: action.error
          },
          latestNotification: {
            ...state.latestNotification,
            status: FAIL,
            error: action.error
          }
        }
      default:
        return state
    }
  }

}

export default new NotificationsReducers()
