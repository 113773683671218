import * as constants from './constants'
import reducers from './reducers'

const defaultState = {
  notifications: {
    size: 10,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: [],
    lastFetchedAt: undefined
  },
  latestNotifications: {
    size: 2,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: [],
    lastFetchedAt: undefined
  },
  reportNotifications: {
    size: undefined,
    page: 1,
    language: undefined,
    error: undefined,
    total: undefined,
    status: undefined,
    results: [],
  },
  totalNotificationsNotViewed: {
    total: 0,
    lastFetchedAt: undefined
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.RESET_NOTIFICATIONS_STORE:
      return defaultState.notifications
    case constants.RESET_LATEST_NOTIFICATIONS_STORE:
      return defaultState.latestNotifications
    case constants.FETCH_NOTIFICATIONS:
      return reducers.fetchNotifications(state, action)
    case constants.FETCH_LATEST_NOTIFICATIONS:
      return reducers.fetchLatestNotifications(state, action)
    case constants.FETCH_REPORT_NOTIFICATIONS:
      return reducers.fetchReportNotifications(state, action)
    case constants.FETCH_NOTIFICATIONS_NEXT:
      return reducers.fetchNotificationsNext(state, action)
    case constants.FETCH_LATEST_NOTIFICATIONS_NEXT:
      return reducers.fetchLatestNotificationsNext(state, action)
    case constants.GET_TOTAL_NOTIFICATION_NOT_VIEWED:
      return reducers.getTotalNotificationsNotViewed(state, action)
    case constants.SET_NOTIFICATIONS_USER_STATUS:
      return reducers.setNotificationUserStatus(state, action)
    case constants.CHANGE_ACTIONED_STATUS:
      return reducers.changeActionedStatus(state, action)
    default:
      return state
  }
}
