export const FETCH_NOTIFICATIONS = 'notifications/FETCH_NOTIFICATIONS'
export const FETCH_LATEST_NOTIFICATIONS = 'notifications/FETCH_LATEST_NOTIFICATIONS'
export const FETCH_REPORT_NOTIFICATIONS = 'notifications/FETCH_REPORT_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_NEXT = 'notifications/FETCH_NOTIFICATIONS_NEXT'
export const FETCH_LATEST_NOTIFICATIONS_NEXT = 'notifications/FETCH_LATEST_NOTIFICATIONS_NEXT'
export const RESET_NOTIFICATIONS_STORE = 'notifications/RESET_NOTIFICATIONS_STORE'
export const RESET_LATEST_NOTIFICATIONS_STORE = 'notifications/RESET_LATEST_NOTIFICATIONS_STORE'
export const SET_NOTIFICATIONS_USER_STATUS = 'notifications/SET_NOTIFICATIONS_USER_STATUS'
export const CHANGE_ACTIONED_STATUS = 'notifications/CHANGE_ACTIONED_STATUS'
export const GET_TOTAL_NOTIFICATION_NOT_VIEWED = 'notifications/GET_TOTAL_NOTIFICATION_NOT_VIEWED'
