import { createSelector } from 'reselect'

import { PENDING } from '../../reduxPromise'
import { FETCH_NOTIFICATIONS_NEXT, FETCH_LATEST_NOTIFICATIONS_NEXT } from './constants'

const getLatestNotificationsStatus = state => state.notifications?.latestNotifications?.status
const getLatestNotificationsAction = state => state.notifications?.latestNotifications?.action
const getReportNotificationsStatus = state => state.notifications?.reportNotifications?.status
const getReportNotificationsAction = state => state.notifications?.reportNotifications?.action
const getNotificationsStatus = state => state.notifications?.notifications?.status
const getNotificationsAction = state => state.notifications?.notifications?.action

export const getLatestNotificationsResults = state => state.notifications?.latestNotifications?.results
export const getLatestNotificationsTotal = state => state.notifications?.latestNotifications?.total
export const getLatestNotificationsError = state => state.notifications?.latestNotifications?.error
export const getLatestNotificationsSize = state => state.notifications?.latestNotifications?.size
export const getLatestNotificationsPage = state => state.notifications?.latestNotifications?.page
export const getLatestNotificationsLastFetchedAt = state => state.notifications?.latestNotifications?.lastFetchedAt

export const getReportNotificationsResults = state => state.notifications?.reportNotifications?.results
export const getReportNotificationsTotal = state => state.notifications?.reportNotifications?.total
export const getReportNotificationsError = state => state.notifications?.reportNotifications?.error
export const getReportNotificationsSize = state => state.notifications?.reportNotifications?.size
export const getReportNotificationsPage = state => state.notifications?.reportNotifications?.page

export const getNotificationsResults = state => state.notifications?.notifications?.results
export const getNotificationsTotal = state => state.notifications?.notifications?.total
export const getNotificationsError = state => state.notifications?.notifications?.error
export const getNotificationsSize = state => state.notifications?.notifications?.size
export const getNotificationsPage = state => state.notifications?.notifications?.page
export const getNotificationsLastFetchedAt = state => state.notifications?.notifications?.lastFetchedAt

export const getTotalNotificationsNotViewed = state => state.notifications.totalNotificationsNotViewed.total
export const getTotalNotificationsNotViewedLastFetchedAt = state => state.notifications.totalNotificationsNotViewed.lastFetchedAt


export const getLatestNotificationsIsLoading = createSelector(
  getLatestNotificationsStatus,
  status => status === PENDING
)

export const getReportNotificationsIsLoading = createSelector(
  getReportNotificationsStatus,
  status => status === PENDING
)

export const getLatestNotificationsIsFetchMore = createSelector([
  getLatestNotificationsAction, getLatestNotificationsStatus
], (action, status) => status === PENDING && action === FETCH_LATEST_NOTIFICATIONS_NEXT)

export const getLatestNotificationsHasMore = createSelector([
  getLatestNotificationsTotal, getLatestNotificationsResults
], (total, results) => total !== null && results?.length < total
)

export const getNotificationsIsLoading = createSelector(
  getNotificationsStatus,
  status => status === PENDING
)

export const getNotificationsIsFetchMore = createSelector([
  getNotificationsAction, getNotificationsStatus
], (action, status) => status === PENDING && action === FETCH_NOTIFICATIONS_NEXT)

export const getNotificationsHasMore = createSelector([
  getNotificationsTotal, getNotificationsResults
], (total, results) => total !== null && results?.length < total
)